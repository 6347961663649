<template>
  <div
    :class="[!!secondaryValue ? 'tw-items-end' : 'tw-items-center', {'no-arrow': withoutArrow}]"
    class="pricing-total"
  >
    <div class="tw-flex tw-justify-between tw-w-full">
      <div
        class="pricing-total-label tw-font-medium tw-pr-4 tw-flex tw-items-center tw-text-base"
        v-text="$options.filters.capitalize(label)"
      />
      <div
        class="tw-flex tw-flex-col tw-items-end"
      >
        <span
          v-if="secondaryValue"
          class="pricing-total-value pricing-total-value-secondary"
          v-text="secondaryValue"
        />
        <span
          class="pricing-total-value pricing-total-primary-value tw-font-medium tw-text-base"
          v-text="primaryValue"
        />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
  /**
   * Generic component to show the total pricing, with a slot for the value
   * @module component - pricingTotal
   * @param {string} label
   * @param {string} value
   *
   * @example
   * <pricing-total
   *  :label="'Pricing label'"
   *  :value="'123,45 €"
   * />
   */
  export default {
    name: 'PricingTotal',
    props: {
      label: {
        type: String,
        required: true
      },
      primaryValue: {
        type: String,
        required: true,
        default: String
      },
      secondaryValue: {
        type: String,
        required: false,
        default: String
      },
      withoutArrow: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
.pricing-total {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 118, 150, var(--tw-bg-opacity));
  border-radius: 0.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
}
.pricing-total-label, .pricing-total-value {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.pricing-total-value {
  margin: 0px;
  white-space: nowrap;
}
.pricing-total::after {
  border-color: transparent;
  --tw-border-opacity: 1;
  border-top-color: rgba(40, 118, 150, var(--tw-border-opacity));
  border-style: solid;
  height: 0px;
  position: absolute;
  width: 0px;
  content: ' ';
  bottom: -($arrow-size);
  left: $arrow-size;
  border-width: $arrow-size $arrow-size 0 $arrow-size;
}
.pricing-total.no-arrow::after {
  content: none;
}
</style>
